import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";

export class FormValidators {
  public static readonly AtLeastOneFieldFormValidator = (fields: string[]): ValidatorFn => {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const group = formGroup as FormGroup;
      let hasAtLeastOne = false;

      fields.forEach(field => {
        const control = group.get(field);

        if (control?.value) {
          hasAtLeastOne = true;
        }
      });

      fields.forEach(field => {
        const control = group.get(field);

        if (control) {
          const errors = control.errors || {};

          if (hasAtLeastOne) {
            delete errors.atLeastOneFieldValidator;
            control.setErrors(Object.keys(errors).length ? errors : null);
          } else {
            errors.atLeastOneFieldValidator = true;
            control.setErrors(errors);
          }
        }
      });

      return hasAtLeastOne ? null : { atLeastOneFieldValidator: true };
    };
  };
}
