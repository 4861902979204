<div class="card mt-200">
    <div class="card-header sk-card-header">
        <h4>{{'settings.profile.connectwise-sso' | translate}}</h4>
    </div>
    <div class="card-block">
        <sk-text-spinner *ngIf="isLoading"></sk-text-spinner>
        <div *ngIf="!isLoading">
            <p *ngIf="!hasLinkedUser" [innerHTML]="'settings.profile.enable-sso' | translate"></p>
            <p *ngIf="hasLinkedUser" [innerHTML]="'settings.profile.enabled-sso' | translate"></p>
            <button *ngIf="!hasLinkedUser"
                [disabled]="isImpersonating()"
                (click)="linkAccount()"
                color="primary"
                class="btn btn-primary">{{'settings.profile.link-account' | translate}}
            </button>
            <button *ngIf="hasLinkedUser"
                sk-spinner-button [isSpinning]="isUnlinkAccountLoading"
                [disabled]="isImpersonating()"
                (click)="unlinkAccount()"
                color="primary"
                class="btn btn-primary">{{'settings.profile.unlink-account' | translate}}
            </button>

            <div *ngIf="isImpersonating()" style="display:flex;color:#f08800">
                <i class="material-icons md-22 icon-warning">error</i>
                <div>Link feature is disabled for impersonator.</div>
            </div>
        </div>
    </div>
</div>