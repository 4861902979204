import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { getCustomFlattenedPermissionName, NavigationGuard, NavigationGuardData, NavigationPermission } from '@skykick/core';
import { CustomPermissions } from 'src/app/utilities/custom-permissions.util';
import { ConnectwiseComponent } from './connectwise/connectwise.component';
import { ConnectWisePartnerResolver } from './connectwise/core/resolvers/connectwise-partner.resolver';
import { CustomersComponent } from './connectwise/customers/customers.component';
import { DetailsComponent } from './connectwise/details/details.component';
import { TicketsComponent } from './connectwise/tickets/tickets.component';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { HomeComponent } from './home/home.component';
import { IntegrationsComponent } from './integrations.component';

export const INTEGRATIONS_ROUTES_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.integrations,
    NavigationPermission.Read
  ),
];

export const INTEGRATIONS_ROUTES: Routes = [
    {
        path: 'integrations/connectwise',
        redirectTo: 'integrations/connectwise/details',
        pathMatch: 'prefix',
    },
    {
        path: 'integrations',
        title: 'ConnectWise - Integrations',
        component: IntegrationsComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            mandatoryPermissions: INTEGRATIONS_ROUTES_PERMISSIONS,
            permissionAbsentRedirect: 'settings'
          } as NavigationGuardData
        },
        pathMatch: 'prefix',
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'connectwise',
                title: 'ConnectWise - ConnectWise PSA Integration',
                component: ConnectwiseComponent,
                resolve: {
                    partnerData: ConnectWisePartnerResolver
                },
                children: [
                    {
                        path: 'details',
                        component: DetailsComponent
                    },
                    {
                        path: 'customers',
                        component: CustomersComponent
                    },
                    {
                        path: 'tickets',
                        component: TicketsComponent
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(INTEGRATIONS_ROUTES, { onSameUrlNavigation: 'reload'})],
    exports: [RouterModule],
    providers: [
        { provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
    ]
})
export class IntegrationsRoutingModule { }
