import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

import { AvatarModule, ButtonsModule, DropdownsModule, FeedbackModule, InputModule, LayoutModule, StatusSpinnerModule, TasksModule } from '@skykick/core';

import { SharedModule } from '../shared/shared.module';
import { AccountModule } from './account/account.module';
import { IntegrationsModule } from './integrations/integrations.module';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { NotificationSettingsModule } from './notifications/notification-settings.module';
import { PaymentModule } from './payment/payment.module';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { ConnectwiseSsoComponent } from './user-profile/connectwise-sso/connectwise-sso.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UsersModule } from './users/users.module';
import { windowProvider, WindowToken } from './window';

@NgModule({
  declarations: [
    SettingsComponent,
    LeftNavComponent,
    UserProfileComponent,
    ConnectwiseSsoComponent
  ],
  imports: [
    SettingsRoutingModule,
    UsersModule,
    NotificationSettingsModule,
    SharedModule,
    AvatarModule,
    LayoutModule,
    TasksModule,
    DropdownsModule,
    ButtonsModule,
    StatusSpinnerModule,
    FeedbackModule,
    InputModule,
    NgbModule,
    ReactiveFormsModule,
    ClipboardModule,
    AccountModule,
    IntegrationsModule,
    PaymentModule
  ],
  providers: [
    CookieService,
    { provide: WindowToken, useFactory: windowProvider },
  ]
})
export class SettingsModule { }
