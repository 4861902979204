import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  NavigationGuardData,
  SkykickPlatformPage,
  TaskManagerService,
} from '@skykick/core';
import {
  AbstractUserProvider,
} from '@skykick/platform-identity-auth-auth0-angular';
import { MembersRoleProvider } from '../../users/components/members/services/members.role.provider';

@Component({
  selector: 'sk-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent
  extends SkykickPlatformPage
  implements OnInit
{
  isConnectWiseUser: boolean;

  constructor(
    activatedRoute: ActivatedRoute,
    taskManagerService: TaskManagerService,
    private abstractUserProvider: AbstractUserProvider,
  ) {
    super(activatedRoute, taskManagerService);
  }

  ngOnInit(): void {
    const currentUser = this.abstractUserProvider.getCurrentUser();
    
    this.isConnectWiseUser = 
      currentUser.hasPermission(MembersRoleProvider.ConnectWiseBackupAdmin.key) ||
      currentUser.hasPermission(MembersRoleProvider.ConnectWiseBackup.key);
  }

  hasPermission(tabName: string): boolean {
    const route = this.activatedRoute.snapshot.routeConfig.children.filter(x => x.data['name'] == tabName)[0];
    const { mandatoryPermissions } = route.data?.navigationGuardData as NavigationGuardData ?? {};
    if (mandatoryPermissions?.length > 0) {
      const currentUser = this.abstractUserProvider.getCurrentUser();
      return mandatoryPermissions.every((permission) =>
        currentUser.hasPermission(permission)
      );
    }
    return true;
  }
}
