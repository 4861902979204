import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExternalAuthCompleteProfileService {
  public externalAuth(token: string) {
    window.location.href = this.getExternalAuthRedirect(token);
  }

  private getExternalAuthRedirect(token: string): string {
    const fullUrl = new URL(window.location.href);
    const baseUrl = (fullUrl.origin + fullUrl.pathname).toLocaleLowerCase();
    const encodedReturnTo = encodeURIComponent(baseUrl);
    const encodedToken = encodeURIComponent(token);

    return `${this.getBaseUri()}/api/external-auth/get-complete-profile-data?token=${encodedToken}&returnTo=${encodedReturnTo}`;
  }

  private getBaseUri() {
    return window.location.href.includes('.com') ? environment.auth.baseUri : environment.auth.alternativeBaseUri;
  }
}
