import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { datadogLogs } from '@datadog/browser-logs';
import * as Intercom from "@intercom/messenger-js-sdk";
import { HelpCenterService, PlatformNavigationComponent } from '@skykick/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUser } from '@skykick/platform-identity-auth-auth0-angular';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PortalTracingService } from './services/tracing/core/portal.tracing.service';
import { TermsAndConditionsService } from './terms-and-conditions/terms-and-conditions.service';

@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(PlatformNavigationComponent)
  public skPlatformNavigation: PlatformNavigationComponent;

  routeData: any;
  currentUser: ISkyKickPartnerPortalUser;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private helpCenter: HelpCenterService,
    private userService: AbstractUserProvider,
    private portalTracingService: PortalTracingService,
    private termsAndConditionsService: TermsAndConditionsService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data)
      )
      .subscribe((event: { [name: string]: any }) => {
        if (event['SkNavigationArea']) {
          this.skPlatformNavigation.SkNavigationArea =
            event['SkNavigationArea'];
        }
    });

    // Initialize the Zendesk help center embeddable.
    this.helpCenter.init();
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();

    Intercom.Intercom({
      app_id: 'itg40p5r',
      email: this.currentUser.email,
      user_id: this.currentUser.userId,
      partner_id: this.currentUser.partnerId,
      name: this.currentUser.fullName,
      avatar: {
          type: 'avatar',
          image_url: this.currentUser.picture
      },
      is_test: this.currentUser.isTestPartner,
      is_admin: ['PartnerPortalAdmin', 'ConnectWiseBackupAdmin'].some(role =>
          this.currentUser.hasPermission(role)
        ),
      horizontal_padding: 20,
      vertical_padding: 20,
    });

    datadogLogs.init({
      clientToken: environment.dataDogTracing.clientToken,
      datacenter: 'us',
      forwardErrorsToLogs: true,
      sampleRate: environment.dataDogTracing.sessionSampleRate,
      env: environment.dataDogTracing.environment,
      version: environment.dataDogTracing.version
    });
    this.portalTracingService.initializePortalTracing(environment.dataDogTracing);

    // Check if user accepted Terms&Conditions
    this.termsAndConditionsService.checkIfAccepted(this.currentUser).pipe(first()).subscribe();
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
