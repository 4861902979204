import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    AppId,
    RouteResolverService,
} from '@skykick/core';

import { environment } from 'src/environments/environment';
import { Utils } from '../../../services/utils';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private basePath = `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${environment.apis.authenticationApimSuffix}/api/user`;

    constructor(private readonly http: HttpClient,
                private readonly routeResolverService: RouteResolverService) { }

    userExists(userName: string): Observable<boolean> {
        return this.http.get<boolean>(
                `${this.basePath}/exists?userName=${encodeURIComponent(userName)}`)
            .pipe(
                catchError(error => Utils.handleError(error, UsersService.name))
            );
    }

    fetchLastLogon(email: string): Observable<string> {
      const encodedEmail = encodeURIComponent(email);

      return this.http.get<string>(
        `${this.basePath}/lastLogon?userName=${encodedEmail}`
      ).pipe(
        catchError(error => Utils.handleError(error, UsersService.name))
      );
    }
}
