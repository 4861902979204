import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppId, RouteResolverService, } from '@skykick/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Utils } from '../../../services/utils';
import { LinkedUserInfoResponse } from '../models/LinkedUserInfoResponse';

@Injectable({
  providedIn: 'root'
})
export class LinkedUserService {
  private basePath = `${this.routeResolverService.generatePipeRoute(AppId.PlatformApis)}${environment.apis.authenticationApimSuffix}`;

  constructor(
    private http: HttpClient, 
    private routeResolverService: RouteResolverService) {}

  getLinkedUser(): Observable<LinkedUserInfoResponse> {
    return this.http.get<LinkedUserInfoResponse>(
      `${this.basePath}/api/user/linked-user`)
      .pipe(
          catchError(error => Utils.handleError(error, LinkedUserService.name))
      );
  }

  deleteLinkedUser(): Observable<void> {
    return this.http.delete<void>(
      `${this.basePath}/api/user/linked-user`)
      .pipe(
          catchError(error => Utils.handleError(error, LinkedUserService.name))
      );
  }
}
