<nav class="navbar sk-portal-header">
    <a href="../"><img class="ml-100" src="https://cdn.skykick.com/logo/masthead-logo.svg" height="28"></a>
</nav>
<div class="sk-content-primary bg-gray-50">
    <div class="sk-content-flex">
        <div *ngIf="loading && !error" class="flex-center-center pb-100">
            <div class="sk-spin-loader"></div>
        </div>
        <div *ngIf="error" class="flex-top-center pb-100">
            <sk-error-banner [title]="errorTitle" [description]="errorDescription" [contactLink]="errorContactLink"></sk-error-banner>
        </div>
        <div *ngIf="!loading" class="sk-scroll-container align-items-center">
            <div>
                <div class="col-lg col-lg-12 mt-200 mb-200 text-lg-center">
                    <h1>{{ 'complete.form.complete_your_account_profile' | translate }}</h1>
                </div>
                <form class="card mb-200" [formGroup]="completeProfileForm">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl mb-150 mt-50 col-xl-12" >
                                <h3>{{ 'complete.form.account' | translate }}</h3>
                            </div>
                        </div>
                        <sk-feedback *ngIf="invitationInvalid" class="mb-100" type="danger"
                            [subject]="'complete.form.actions.invitation_link_is_invalid' | translate" [message]="'complete.form.actions.contact_partner' | translate">
                        </sk-feedback>
                        <sk-feedback *ngIf="invitationUsed" class="mb-100" type="info"
                            [subject]="'complete.form.actions.profile_is_completed' | translate">
                            <span translate>complete.form.actions.you_can_login</span>&nbsp;<a [href]="portalUrl">{{ 'complete.form.actions.skykick_portal' | translate }}</a>
                        </sk-feedback>
                        <sk-feedback *ngIf="invitationExpired" class="mb-100" type="info"
                            [subject]="'complete.form.actions.invitation_link_expired' | translate" [message]="'complete.form.actions.check_your_email' | translate">
                        </sk-feedback>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                                <label class="form-label font-bold">{{ 'complete.form.first_name' | translate }}</label>
                                <input type="text" class="form-control" name="firstName" formControlName="firstName" [class.is-invalid]="isFieldInvalid('firstName')" />
                                <small *ngIf="isFieldEmpty('firstName')" class="invalid-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                                <small *ngIf="isFieldPatternInvalid('firstName')" class="invalid-feedback">{{ 'complete.form.errors.name_field_pattern_is_invalid' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                                <label class="form-label font-bold">{{ 'complete.form.last_name' | translate }}</label>
                                <input type="text" class="form-control" name="lastName" formControlName="lastName" [class.is-invalid]="isFieldInvalid('lastName')" />
                                <small *ngIf="isFieldEmpty('lastName')" class="invalid-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                                <small *ngIf="isFieldPatternInvalid('lastName')" class="invalid-feedback">{{ 'complete.form.errors.name_field_pattern_is_invalid' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" >
                                <label class="form-label font-bold">{{ 'complete.form.email_address' | translate }}</label>
                                <input type="text" class="form-control" name="emailAddress" value="{{ emailAddress }}" disabled />
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                                <label class="form-label font-bold">{{ 'complete.form.job_title' | translate }}</label>
                                <input type="text" class="form-control" name="jobTitle" formControlName="jobTitle" [class.is-invalid]="isFieldInvalid('jobTitle')" />
                                <small *ngIf="isFieldInvalid('jobTitle')" class="invalid-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                                <label class="form-label font-bold">{{ 'complete.form.cell_phone' | translate }}</label>
                                <input type="text" class="form-control" name="cellPhone" formControlName="cellPhone" [class.is-invalid]="isFieldInvalid('cellPhone')" />
                                <small *ngIf="isFieldInvalid('cellPhone')" class="invalid-feedback">{{ 'complete.form.errors.phone_required' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="col-sm mb-100 col-sm-8" [disabled]="formDisabled">
                                <label class="form-label font-bold">{{ 'complete.form.business_phone' | translate }}</label>
                                <input type="text" class="form-control" name="businessPhone" formControlName="businessPhone" [class.is-invalid]="isFieldInvalid('businessPhone')" />
                                <small *ngIf="isFieldInvalid('businessPhone')" class="invalid-feedback">{{ 'complete.form.errors.phone_required' | translate }}</small>
                            </fieldset>
                            <fieldset class="col-sm mb-100 col-sm-4" [disabled]="formDisabled">
                                <label class="form-label font-bold">{{ 'complete.form.extension' | translate }}</label>
                                <input type="text" class="form-control" name="extension" formControlName="extension" [class.is-invalid]="isFieldInvalid('extension')" />
                                <small *ngIf="isFieldPatternInvalid('extension')" class="invalid-feedback">{{ 'complete.form.errors.extension_field_pattern_is_invalid' | translate }}</small>
                                <small *ngIf="isFieldRangeInvalid('extension')" class="invalid-feedback">{{ 'complete.form.errors.extension_field_range_is_invalid' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <div class="col-xl mb-150 mt-150 col-xl-12" >
                                <h3>{{ 'complete.form.password' | translate }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-start">
                                        <label for="createPassword" class="font-bold">{{ 'complete.form.create_password' | translate }}</label>
                                    </div>
                                    <div class="flex">
                                        <button type="button" class="btn sk-btn-icon sk-password-toggle border-0">
                                            <i class="material-icons md-18 sk-password-show icon-muted" (click)="togglePassword()" (keyup.enter)="togglePassword()">{{showPassword ? 'visibility' : 'visibility_off'}}</i>
                                        </button>
                                    </div>
                                </div>
                                <div class="sk-input-group">
                                    <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="createPassword"
                                        name="password" formControlName="password" [ngbPopover]="passwordPopover" placement="bottom"
                                        [autoClose]="'outside'" popoverClass="password-popover" [class.is-invalid]="isFieldInvalid('password') || isPasswordInvalid()">
                                </div>
                            <small *ngIf="isFieldInvalid('password') && completeProfileForm.controls.password.hasError('required')" class="invalid-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                                <ng-template #passwordPopover>
                                  <div class="password-popover-title">{{'platform.password.requirements' | translate}}:</div>
                                  <div class="password-rule-error" [class.password-rule-valid]="isPasswordRuleValid('minlength') && isPasswordRuleValid('maxlength')">{{ 'platform.password.length' | translate }}</div>
                                  <div class="password-rule-error" [class.password-rule-valid]="!hasPasswordPatternErrors()">{{ 'platform.password.pattern' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasLowerCase')">{{ 'platform.password.lowercase' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasUpperCase')">{{ 'platform.password.uppercase' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasNumber')">{{ 'platform.password.number' | translate }}</div>
                                  <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasSpecialCharacters')">{{ 'platform.password.special_character' | translate }}</div>
                                </ng-template>
                            </fieldset>
                        </div>
                        <div class="row">
                            <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-start">
                                        <label for="reenteredPassword" class="font-bold">{{ 'complete.form.reenter_password' | translate }}</label>
                                    </div>
                                    <div class="flex">
                                        <button type="button" class="btn sk-btn-icon sk-password-toggle border-0">
                                            <i class="material-icons md-18 sk-password-show icon-muted" (click)="toggleReenteredPassword()" (keyup.enter)="toggleReenteredPassword()">{{showReenteredPassword ? 'visibility' : 'visibility_off'}}</i>
                                        </button>
                                    </div>
                                </div>
                                <div class="sk-input-group">
                                    <input [type]="showReenteredPassword ? 'text' : 'password'" class="form-control" id="reenteredPassword"
                                        name="reenteredPassword" formControlName="reenteredPassword"
                                        [class.is-invalid]="isFieldInvalid('reenteredPassword') || isPasswordInvalid()">
                                </div>
                                <small *ngIf="isFieldInvalid('reenteredPassword')" class="invalid-feedback">{{ 'complete.form.errors.field_is_empty' | translate }}</small>
                                <small *ngIf="isPasswordInvalid()" class="invalid-feedback">{{ 'complete.form.errors.passwords_do_not_match' | translate }}</small>
                            </fieldset>
                        </div>
                        <div class="row">
                            <div class="d-grid col-xl mb-50 mt-50 col-xl-12">
                                <button sk-spinner-button [color]="'primary'" class="btn-lg" (click)="completeMemberProfile()"
                                    [disabled]="formDisabled" [isSpinning]="executingMainAction">{{ 'complete.form.actions.save_and_login' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    <div class="mb-100">
                        <div ngbDropdown class="btn-group dropup m-b-1" [ngClass]="{'open': openLanguageDropdown}">
                            <button ngbDropdownToggle type="button" (click)="toggleLanguageDropdown($event)" class="btn btn-basic dropdown-toggle">{{ 'complete.form.language' | translate }}</button>
                            <div ngbDropdownMenu class="dropdown-menu">
                                <a ngbDropdownItem class="dropdown-item" [ngClass]="{'active': language === selectedLanguage}" (click)="setLanguage(language)" *ngFor="let language of languages">
                                    {{ 'platform.languages.' + language | translate }}
                                </a>
                            </div>
                        </div>&nbsp;&nbsp;
                        <span>{{ 'complete.form.copyright' | translate }} {{ year }} {{ 'complete.form.all_rights_reserved' | translate }}</span>&nbsp;&nbsp;
                        <a href="https://www.skykick.com/partner-terms-conditions">{{ 'complete.form.terms_and_conditions' | translate }}</a>&nbsp;&nbsp;
                        <a href="https://www.skykick.com/privacy-policy">{{ 'complete.form.privacy_policy' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
