import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getCustomFlattenedPermissionName, NavigationGuard, NavigationGuardData, NavigationPermission } from '@skykick/core';
import { CustomPermissions } from 'src/app/utilities/custom-permissions.util';
import { AccountComponent } from './account/account.component';

export const ACCOUNT_ROUTES_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.account,
    NavigationPermission.Read
  ),
];

export const ACCOUNT_ROUTES: Routes = [
  {
    path: 'account',
    title: 'ConnectWise - Account',
    component: AccountComponent,
    canActivate: [NavigationGuard],
    data: {
      navigationGuardData: {
        mandatoryPermissions: ACCOUNT_ROUTES_PERMISSIONS,
        permissionAbsentRedirect: 'settings'
      } as NavigationGuardData
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(ACCOUNT_ROUTES)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
