import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModal, SkyKickModalService, SkyKickModalWarningOptions, WarningModalComponent } from '@skykick/core';
import { AbstractUserProvider, ISkyKickPartnerPortalUserImpersonationDetailsProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil, tap } from 'rxjs';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';
import { ExternalAuthService } from '../../shared/services/external-auth.service';
import { LinkedUserService } from '../../shared/services/linked-user.service';

@Component({
  selector: 'sk-connectwise-sso',
  templateUrl: './connectwise-sso.component.html',
  styleUrl: './connectwise-sso.component.scss'
})
export class ConnectwiseSsoComponent implements OnInit, OnDestroy {
  constructor(
    private readonly iSkyKickPartnerPortalUserImpersonationDetailsProvider: ISkyKickPartnerPortalUserImpersonationDetailsProvider,
    private readonly userProvider: AbstractUserProvider,
    private readonly linkedUserService: LinkedUserService,
    private readonly externalAuthService: ExternalAuthService,
    private readonly errorFactory: ErrorFactory,
    private readonly modalService: SkyKickModalService,
    private readonly translateService: TranslateService,
    private readonly toastrService: ToastrService) 
    {}

  private onDestroy$ = new Subject<void>();

  hasLinkedUser: boolean;
  isLoading = true;
  isUnlinkAccountLoading: boolean;

  ngOnInit(): void {
    this.linkedUserService.getLinkedUser().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe({
      next: () => {
        this.hasLinkedUser = true;
        this.isLoading = false;
      },
      error: error => {
        if (error.error.statusCode === 404) {
          this.hasLinkedUser = false;
        }
        this.isLoading = false;
      }
    });
  }

  linkAccount() {
    this.externalAuthService.externalAuth();
  }

  unlinkAccount() {
    let warningModal: SkyKickModal<WarningModalComponent, void>;
    const options: SkyKickModalWarningOptions = {
      body: `<p class="mb-200">${this.translateService.instant('settings.profile.unlink-account-modal.description')}</p>`,
      title: this.translateService.instant('settings.profile.unlink-account-modal.title'),
      btnLabel: this.translateService.instant('settings.profile.unlink-account-modal.yes'),
      alternative: {
          btnLabel: this.translateService.instant('settings.profile.unlink-account-modal.cancel'),
          btnCallback: () => {
            warningModal.dismiss();
          }
      }
    };
    warningModal = this.modalService.warning(options);
    warningModal.result.then(res => {
      if (res.wasClosed) {
        this.isUnlinkAccountLoading = true;
        this.linkedUserService.deleteLinkedUser().pipe(
          takeUntil(this.onDestroy$)
        ).subscribe({
          next: () => {
            this.toastrService.success(this.translateService.instant('settings.profile.unlink-account-modal.unlink-account-success'));
            this.isUnlinkAccountLoading = false;
            this.hasLinkedUser = false;
          },
          error: error => {
            this.errorFactory.getMessage(error.error).pipe(
              tap((errorText: string) => this.toastrService.error(errorText))
            )
            this.isUnlinkAccountLoading = false;
          }
        });
      }
    });
  }

  isImpersonating() {
    return this.iSkyKickPartnerPortalUserImpersonationDetailsProvider.getImpersonationDetails(this.userProvider.getCurrentUser()).isImpersonation;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
