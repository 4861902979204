export class MembersRoleProvider {
  static readonly RoleDisplayNameLocKeys = {
    admin: 'settings.members.table.filter.roles.admin',
    unassigned: 'settings.members.table.filter.roles.collaborator',
  };

  static readonly PartnerPortalAdmin = {
    key: 'PartnerPortalAdmin',
    displayNameLocKey: this.RoleDisplayNameLocKeys.admin,
    isAdmin: true,
  };

  static readonly PartnerPortal = {
    key: 'PartnerPortal',
    displayNameLocKey: this.RoleDisplayNameLocKeys.unassigned,
    isAdmin: false,
  };

  static readonly ConnectWiseBackupAdmin = {
    key: 'ConnectWiseBackupAdmin',
    displayNameLocKey: this.RoleDisplayNameLocKeys.admin,
    isAdmin: true,
  };

  static readonly ConnectWiseBackup = {
    key: 'ConnectWiseBackup',
    displayNameLocKey: this.RoleDisplayNameLocKeys.unassigned,
    isAdmin: false,
  };

  static readonly PartnerPortalRoles = [
    MembersRoleProvider.PartnerPortalAdmin,
    MembersRoleProvider.PartnerPortal,
  ];

  static readonly ConnectWiseBackupRoles = [
    MembersRoleProvider.ConnectWiseBackupAdmin,
    MembersRoleProvider.ConnectWiseBackup,
  ];

  static readonly Roles = [
    ...this.PartnerPortalRoles,
    ...this.ConnectWiseBackupRoles,
  ];

  public static getRoleDisplayNameLocKeyByKey(
    key: string,
    includeNonAdminName: boolean
  ): string {
    const role = this.Roles.find((x) => x.key === key);
    return role?.isAdmin || includeNonAdminName ? role?.displayNameLocKey : '';
  }

  public static isAdmin(userRole: string): boolean {
    return (
      this.Roles.find((x) => x.key === userRole)?.isAdmin ?? false
    );
  }
}
