import {
  Component,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SkyKickModalService } from '@skykick/core';

import { DnsManagerService } from '../developer/services/dns-manager.service';
import { DnsValidateService } from '../developer/services/dns-validate.service';
import { DnsOrder } from './models/DnsOrder';
import { RecordType } from './models/RecordType';
import { ZoneRecords } from './models/ZoneRecords';

@Component({
  selector: 'sk-dns-manager',
  templateUrl: './dns-manager.component.html',
  styleUrls: ['./dns-manager.component.scss']
})

export class DnsManagerComponent implements OnInit {
  orders: Array<DnsOrder> = [];
  selectedZone: DnsOrder;
  RecordType = RecordType;
  orderRecords: ZoneRecords;
  selectedOrderDomain = 'Retreiving Domains...';
  gettingOrders = true;
  modalSpinner = false;
  editModal: boolean;
  modalRecordType: RecordType;
  editingRecord: any;
  ttlList: {[key: string]: Object }[] = [{ value: '3600', name: '1 hr' },
                                         { value: '14400', name: '4 hr' }, { value: '28800', name: '8 hr' },
                                         { value: '86400', name: '24 hr' }, { value: '172800', name: '48 hr' }];

  modalForm = this.formBuilder.group({
    recordName: '',
    ip: '',
    ipv6: '',
    ttl: 3600,
    priority: null,
    domain: '',
    weight: null,
    port: null,
    value: ''
  });

  constructor(private dnsManagerService: DnsManagerService,
              private modalService: NgbModal,
              private formBuilder: UntypedFormBuilder,
              private skModalService: SkyKickModalService,
              private toastrService: ToastrService,
              private dnsValidateService: DnsValidateService,
            ) { }


  ngOnInit(): void {
    this.getOrdersForDropDown();
  }

  getOrdersForDropDown(): void {
    this.gettingOrders = true;
    this.dnsManagerService.getOrders().subscribe({
      next: x => {
        this.orders.push(...x);

        if (this.orders.length > 0) {
          this.getRecordsForOrder(this.orders[0]);
        }
        else {
          this.selectedOrderDomain = 'No Zones Found';
          this.gettingOrders = false;
        }
      },
      error: () => {
        this.selectedOrderDomain = 'Error getting zones';
        this.gettingOrders = false;
        this.retrieveErrorModal();
      }
    });
  }

  getRecordsForOrder(order: DnsOrder): void {
    this.selectedOrderDomain = 'Retreiving zone...';
    this.gettingOrders = true;
    this.selectedZone = order;
    this.dnsManagerService.getZone(order).subscribe({
      next: (x) => {
        this.orderRecords = x;
        this.selectedOrderDomain = order.domain;
        this.gettingOrders = false;
      },
      error: _ => {
        this.gettingOrders = false;
        this.retrieveErrorModal();
    }});
  }

  open(content: any, recordType: RecordType, editing: boolean, record?: any): void {
    this.modalRecordType = recordType;
    this.editModal = editing;
    this.editingRecord = record;

    // If opening edit modal, populate inputs with data
    if (this.editModal) {
      this.modalForm = this.formBuilder.group({
        recordName: record.name,
        ip: (recordType === RecordType.A) ? record.ipAddress : '',
        ipv6: (recordType === RecordType.AAAA) ? record.iPv6Address : '',
        ttl: record.timeToLive,
        domain: (recordType === RecordType.MX || recordType === RecordType.CNAME ||
                 recordType === RecordType.SRV || recordType === RecordType.PTR ) ? record.domainName : '',
        priority: (recordType === RecordType.MX || recordType === RecordType.SRV ) ? record.priority : null,
        weight: (recordType === RecordType.SRV) ? record.weight : null,
        port: (recordType === RecordType.SRV) ? record.port : null,
        value: (recordType === RecordType.TXT) ? record.value : '',
      });
    }

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  destructiveModal(record: any, recordType: RecordType) {
    const modal = this.skModalService.destructive({
        title: 'Confirm',
        body: 'Are you sure you want to delete this item?',
        btnLabel: 'Confirm',
        alternative: {
            btnLabel: 'Cancel',
            btnCallback: () => { modal.dismiss(); },
        },
    });

    modal.result$.subscribe(res => {
      if (res.wasClosed) {
        this.deleteRecord(record, recordType);
      }
    })
  }

  onSubmit(): void {
    this.modalSpinner = true;
    if (this.editModal) {
      this.modifyRecord(this.modalForm);
    }
    else {
      this.createRecord(this.modalForm);
    }
  }

  modalDismiss(): void {
    this.modalSpinner = false;
    this.resetFormData();
    this.modalService.dismissAll();
  }

  resetFormData(): void {
    this.modalForm = this.formBuilder.group({
      recordName: '',
      ip: '',
      ipv6: '',
      ttl: 3600,
      mailServerHostName: '',
      priority: null,
      domain: '',
      weight: null,
      port: null,
      serverHostName: '',
      value: ''
    });
  }

  createRecord(formData: UntypedFormGroup): void {
    if (this.modalRecordType === RecordType.MX) {
      if (!this.dnsValidateService.validatemxRecordDomain(formData.value.domain)) {
        this.mxDomainErrorModal();
        this.modalSpinner = false;
        return null;
      }
    }
    const newRecord = this.createNewRecordObject(this.modalRecordType, formData);

    this.dnsManagerService.createRecord(this.selectedZone, newRecord, this.modalRecordType)
    .subscribe({
      complete: () => {
        this.modalDismiss();
        this.getRecordsForOrder(this.selectedZone);
        this.toastrService.success('Created successfully');
      },
      error: () => {
        this.modalSpinner = false;
        this.submitErrorModal();
      }
    });
  }

  modifyRecord(formData: UntypedFormGroup): void {
    if (this.modalRecordType === RecordType.MX) {
      if (!this.dnsValidateService.validatemxRecordDomain(formData.value.domain))
      {
        this.mxDomainErrorModal();
        this.modalSpinner = false;
        return null;
      }
    }
    this.updateRecordObject(this.modalRecordType, formData );
    this.dnsManagerService.updateRecord(this.selectedZone, this.editingRecord, this.modalRecordType )
    .subscribe({
      complete: () => {
        this.modalDismiss();
        this.getRecordsForOrder(this.selectedZone);
        this.toastrService.success('Updated successfully');
      },
      error: () => {
        this.modalSpinner = false;
        this.submitErrorModal();
      }
    });
  }

  deleteRecord(record: any, recordType: RecordType): void {
    this.dnsManagerService.removeRecord(this.selectedZone, record, recordType)
      .subscribe({
        complete: () => {
          this.modalDismiss();
          this.getRecordsForOrder(this.selectedZone);
          this.toastrService.success('Deleted successfully');
        },
        error: () => {
          this.submitErrorModal();
        },
    });
  }

  private createNewRecordObject(recordType: RecordType, modalForm: UntypedFormGroup): any {
    switch (recordType){
      case RecordType.A: {
        return {
          ipAddress: modalForm.value.ip,
          name: modalForm.value.recordName,
          timeToLive: modalForm.value.ttl
        };
      }
      case RecordType.AAAA: {
        return {
          iPv6Address: modalForm.value.ipv6,
          name: modalForm.value.recordName,
          timeToLive: modalForm.value.ttl
        };
      }
      case RecordType.CNAME: {
        return {
          name: modalForm.value.recordName,
          domainName: modalForm.value.domain,
          timeToLive: modalForm.value.ttl
        };
      }
      case RecordType.MX: {
        return {
          domainName: modalForm.value.domain,
          name: modalForm.value.recordName,
          priority: modalForm.value.priority,
          timeToLive: modalForm.value.ttl
        };
      }
      case RecordType.SRV: {
        return {
          domainName: modalForm.value.domain,
          name: modalForm.value.recordName,
          priority: modalForm.value.priority,
          weight: modalForm.value.weight,
          port: modalForm.value.port,
          timeToLive: modalForm.value.ttl
        };
      }
      case RecordType.TXT: {
        return {
          name: modalForm.value.recordName,
          timeToLive: modalForm.value.ttl,
          recordValue: modalForm.value.value,
        };
      }
      case RecordType.PTR: {
        return {
          domainName: modalForm.value.domain,
          name: modalForm.value.recordName,
          timeToLive: modalForm.value.ttl
        };
      }
      default: {
        return null;
      }
    }
  }

  private updateRecordObject(recordType: RecordType, modalForm: UntypedFormGroup): void {
    switch (recordType){
      case RecordType.A: {
        this.editingRecord.ipAddress = modalForm.value.ip;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      case RecordType.AAAA: {
        this.editingRecord.iPv6Address = modalForm.value.ipv6;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      case RecordType.CNAME: {
        this.editingRecord.domainName = modalForm.value.domain;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      case RecordType.MX: {
        this.editingRecord.domainName = modalForm.value.domain;
        this.editingRecord.priority = modalForm.value.priority;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      case RecordType.SRV: {
        this.editingRecord.domainName = modalForm.value.domain;
        this.editingRecord.priority = modalForm.value.priority;
        this.editingRecord.weight = modalForm.value.weight;
        this.editingRecord.port = modalForm.value.port;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      case RecordType.TXT: {
        this.editingRecord.recordValue = modalForm.value.value;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      case RecordType.PTR: {
        this.editingRecord.domainName = modalForm.value.domain;
        this.editingRecord.timeToLive = modalForm.value.ttl;
        break;
      }
      default: {
        return null;
      }
    }
  }

  submitErrorModal(): void {
    this.skModalService.error({
      title: 'Error',
      body: `<p>There was a problem performing this action on the DNS record. Please check that the values are valid and re-submit.</p>
        <span class="text-muted ng-binding ng-scope" ng-bind-html="vm.Config.hint">Please try again or contact <a target="\&quot;_blank\&quot;" href="/support/get-support">ConnectWise Support</a></span>`,
      btnLabel: 'OK',
    });
  }

  retrieveErrorModal(): void {
    this.skModalService.error({
      title: 'Error',
      body: `<p>Oops! Something has gone wrong. Please close this panel and try again. Contact support if this continues to happen.</p>
        <span class="text-muted ng-binding ng-scope" ng-bind-html="vm.Config.hint">Please try again or contact <a target="\&quot;_blank\&quot;" href="/support/get-support">ConnectWise Support</a></span>`,
      btnLabel: 'OK',
    });
  }

  mxDomainErrorModal(): void {
    this.skModalService.error({
      title: 'Error',
      body: `<p>The domain does not pass validation!</p>
        <span class="text-muted ng-binding ng-scope" ng-bind-html="vm.Config.hint">Please try again or contact <a target="\&quot;_blank\&quot;" href="/support/get-support">ConnectWise Support</a></span>`,
      btnLabel: 'OK',
    });
  }
}
