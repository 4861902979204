<h3 class="sk-panel-header">{{ 'settings.navigation.settings' | translate }}</h3>
<div class="sk-panel-nav">
    <ul class="nav sk-nav-vert flex-column">
        <li class="nav-item" *ngIf="hasAccessToProfiles">
            <a class="nav-link" routerLink="profile" routerLinkActive="active">
                {{ 'settings.navigation.profile' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="hasAccessToAccount">
                <a class="nav-link" routerLink="account" routerLinkActive="active">
                {{ 'settings.navigation.account' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="hasAccessToUsers">
            <a class="nav-link" routerLink="users" routerLinkActive="active">
                {{ 'settings.navigation.users' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="hasAccessToPayment">
            <a class="nav-link" routerLink="payment" routerLinkActive="active">
            {{ 'settings.navigation.payment' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="hasAccessToIntegrations">
            <a class="nav-link" routerLink="integrations" routerLinkActive="active">
                {{ 'settings.navigation.integrations' | translate }}</a>
        </li>
        <li class="nav-item" *ngIf="hasAccessToNotifications">
            <a class="nav-link" routerLink="notifications" routerLinkActive="active">
                {{ 'settings.navigation.notifications' | translate }}</a>
        </li>
    </ul>
</div>
