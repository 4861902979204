import { Component } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { MembersRoleProvider } from '../../users/components/members/services/members.role.provider';

@Component({
  selector: 'sk-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
  isConnectWiseUser: boolean;

  constructor(private abstractUserProvider: AbstractUserProvider) {}

  ngOnInit() {
    const currentUser = this.abstractUserProvider.getCurrentUser();

    this.isConnectWiseUser = 
      currentUser.hasPermission(MembersRoleProvider.ConnectWiseBackupAdmin.key) ||
      currentUser.hasPermission(MembersRoleProvider.ConnectWiseBackup.key);
  }
}
