<div class="modal-body" [ngClass]="{'step-update' : settingType !== 'wizard' , 'step-init' : settingType === 'wizard' }">

    <div class="d-flex my-150 align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center">
          <img src="https://cdn.skykick.com/logo/app-icon.svg" alt="ConnectWise Logo" class="img-fluid d-flex" />
        </div>
    </div>

    <h3>Connect Cloud Backup to PSA</h3>
    <p class="text-muted">Log into your ConnectWise account with your API keys</p>


    <form *ngIf="!isLoadingCheckingAccess" [formGroup]="authenticateForm" novalidate>
        <fieldset class="mb-100 input-width-max">
            <label class="font-medium">ConnectWise URL</label>
            <input type="text" class="form-control" formControlName="connectWiseURL" id="connectWiseURL" [ngClass]="displayFieldCss('connectWiseURL')" />
            <small class="sk-form-text text-muted">The URL used to sign into ConnectWise Manage</small>
            <small *ngIf="isFieldValid('connectWiseURL', 'required')" class="invalid-feedback">Required field</small>
        </fieldset>
        <fieldset class="mb-100 input-width-max">
            <label class="font-medium">ConnectWise Company Name</label>
            <input type="text" class="form-control" formControlName="companyName" id="companyName" [ngClass]="displayFieldCss('companyName')" />
            <small class="sk-form-text text-muted">The Company ID used to sign into ConnectWise Manage</small>
            <small *ngIf="isFieldValid('companyName', 'required')" class="invalid-feedback">Required field</small>
        </fieldset>
        <fieldset class="mb-100 input-width-max">
            <label class="font-medium">Public Key</label>
            <input type="password" class="form-control" formControlName="publicKey" id="publicKey" [ngClass]="displayFieldCss('publicKey')" />
            <small *ngIf="isFieldValid('publicKey', 'required')" class="invalid-feedback">Required field</small>
        </fieldset>
        <fieldset class="mb-100 input-width-max">
            <label class="font-medium">Private Key</label>
            <input type="password" class="form-control" formControlName="privateKey" id="privateKey" [ngClass]="displayFieldCss('privateKey')" />
            <small *ngIf="isFieldValid('privateKey', 'required')" class="invalid-feedback">Required field</small>
        </fieldset>

        <ng-container *ngIf="authenticateForm.invalid && !authenticateForm.pristine">
            <div class="mt-100">
                <div class="mb-100 is-invalid">
                    <small *ngIf="authenticateForm.errors?.['urlInvalid']" class="invalid-feedback">Invalid ConnectWise Url</small>
                    <small *ngIf="authenticateForm.errors?.['urlOrCompanyInvalid']" class="invalid-feedback">Invalid ConnectWise Url / Company Name</small>
                    <small *ngIf="authenticateForm.errors?.['credsInvalid']" class="invalid-feedback">Incorrect public/private key</small>
                </div>
            </div>
        </ng-container>

        <ng-container>
          <div class="form-check mt-100">
              <input type="checkbox" id="tandc" class="form-check-input" formControlName="tandc"/>
              <label for="tandc" class="form-check-label">By clicking on this box, I accept these additional
                  <div class="btn-group">
                    <a class="btn dropdown-toggle tandc-control"
                        (click)="$event.stopPropagation();showTandC = !showTandC">Terms & Conditions</a>
                  </div>
              </label>
          </div>
          <small *ngIf="showTandC">
            <div class="mt-25 pl-200 text-muted">
              This configuration tool will allow you to create a ConnectWise PSA integration 
              with your ConnectWise Cloud Backup portal account and transmit certain information and related 
              data about your subscriptions to ConnectWise PSA.
            </div>
            <div class="mt-100 pl-200 text-muted">
              By clicking “I Accept” below, you acknowledge, on behalf of the 
              entity you represent, that you have read, understand, and agree the Terms 
              and Conditions. ConnectWise hereby disclaims all responsibility and liability for, 
              and makes no representation or warranties with respect to, the Processing of Data 
              once it has been transmitted to ConnectWise PSA, including but not limited to any unauthorized 
              use or disclosure of the Data, whether by ConnectWise or any other third party.
            </div>
          </small>
        </ng-container>

        <div class="d-flex flex-row mt-150 align-items-center">
            <button id="validateButton" sk-spinner-button [color]="'tertiary'" (click)="validate()" [isSpinning]="isVerifyingCheckingAccess"
                [disabled]="authenticateForm.invalid || isVerifyingCheckingAccess">Validate
            </button>
            <i *ngIf="verificationCheckingAccessSuccessful" class="material-icons md-22 ml-50 icon-light d-flex icon-success">check_circle</i>
            <small *ngIf="!isPermissionsValid" class="ml-50 d-flex align-items-center">
                <i class="material-icons md-22 d-flex icon-danger">warning</i>
                <span class="px-1 icon-danger">API Member missing required permissions.</span>
                <span>
                    <a href="https://support.skykick.com/hc/en-us/articles/360003975094-ConnectWise-PSA-Integration-Setup-Guide" target="_blank">Learn more</a>
                </span>
            </small>
        </div>

    </form>
    <sk-text-spinner *ngIf="isLoadingCheckingAccess" message="Loading credentials"></sk-text-spinner>
</div>

<div class="modal-footer justify-content-start">
    <button *ngIf="settingType === 'wizard'" id="submitBtn" sk-spinner-button [color]="'primary'" (click)="submit()" [isSpinning]="authenticateForm.disabled || isBusy"
        [disabled]="authenticateForm.invalid || !credentials || isBusy || requiresValidation">Save & continue
    </button>
    <button *ngIf="settingType !== 'wizard'" id="validateButton" sk-spinner-button [color]="'primary'" (click)="updateConnectWisePartner()" [isSpinning]="authenticateForm.disabled || isBusy"
        [disabled]="authenticateForm.invalid || isBusy || requiresValidation">Update
    </button>
    <button id="cancelBtn" type="button" class="btn btn-outline-secondary" [class.disabled]="authenticateForm.disabled" (click)="activeModal.dismiss()">Cancel</button>
</div>
