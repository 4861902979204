import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  NoAccessComponent,
  NotFoundComponent
} from '@skykick/core';
import { BuiltWithLoveComponent } from './built-with-love/built-with-love.component';
import { CompleteMemberProfileFormComponent } from './complete-member-profile-form/complete-member-profile-form.component';
import { GageComponent } from './gage/gage.component';
import { LogoutComponent } from './logout/logout.component';
import { MpnRegistrationComponent } from './mpn-registration/component/mpn-registration.component';
import { PORTAL_ROUTES } from './portal/portal-routing.module';
import { LoginGuard } from './utilities/login.guard';
import { ConnectWiseProfileFormComponent } from "./connectwise-signup/connectwise-profile-form";

const routes: Routes = [
  {
    path: '',
    children: [...PORTAL_ROUTES],
  },
  {
    path: 'gage',
    canActivate: [LoginGuard],
    component: GageComponent,
  },
  {
    path: 'complete',
    title: 'ConnectWise - Complete Profile',
    component: CompleteMemberProfileFormComponent,
  },
  {
    path: 'connectwise-complete',
    title: 'ConnectWise - Complete Profile',
    component: ConnectWiseProfileFormComponent,
  },
  {
    path: 'accountsetup',
    title: 'ConnectWise - Account Setup',
    component: MpnRegistrationComponent,
  },
  {
    path: 'platform/builtwithlove',
    title: 'ConnectWise - Built with Love',
    component: BuiltWithLoveComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'unauthorized',
    component: NoAccessComponent,
  },
  {
    path: '**',
    title: 'ConnectWise - Not Found',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
