<div class="sk-page-header">
    <div class="sk-navbar-primary single-navbar">
      <div class="d-flex align-items-center min-w-0">
        <div class="d-flex min-w-0">
          <div class="min-w-0">
            <h3>{{'settings.profile.user-profile' | translate}}</h3>
          </div>
        </div>
      </div>
    </div>
</div>

<div class="sk-page-content">
    <div class="sk-content-primary">
      <div class="sk-flex-table">
        <div class="sk-scroll-container">
          <div class="d-flex flex-column min-width-lg max-width-lg py-200">
            <div class="row">
              <div class="col-sm-5">

                <!-- Personal Info -->
                <div class="card">
                    <div class="card-header sk-card-header d-flex justify-content-between">
                        <h4>{{'settings.profile.personal-info' | translate}}</h4>
                    </div>
                    <div class="card-block">
                        <div name="personalInfo" novalidate="">
                            <form class="mb-100"
                                    [formGroup]="personalInfoForm"
                                    (ngSubmit)="onSubmitPersonalInfoForm()">

                                <label for="firstNameInput" class="font-medium">{{'settings.profile.name' | translate}}</label>
                                <input id="firstNameInput" class="form-control mb-100" formControlName="firstNameInput" type="text" placeholder="first name">

                                <label for="lastNameInput" class="font-medium">{{'settings.profile.last-name' | translate}}</label>
                                <input id="lastNameInput" class="form-control mb-100" formControlName="lastNameInput" type="text" placeholder="last name">

                                <label for="emailInput" class="font-medium">{{'settings.profile.email' | translate}}</label>
                                <input id="emailInput" class="form-control mb-100" formControlName="emailInput" type="text" placeholder="email">

                                <label for="phoneInput" class="font-medium">{{'settings.profile.phone' | translate}}</label>
                                <input id="phoneInput" class="form-control mb-100" formControlName="phoneInput" type="text" placeholder="phone" [class.is-invalid]="isFieldInvalid('phoneInput')">

                                <label for="cellPhoneInput" class="font-medium">{{'settings.profile.cell-phone' | translate}}</label>
                                <input id="cellPhoneInput" class="form-control mb-100" formControlName="cellPhoneInput" type="text" placeholder="cell phone" [class.is-invalid]="isFieldInvalid('cellPhoneInput')">

                                <label for="usernameInput" class="font-medium">{{'settings.profile.username' | translate}}</label>
                                <input id="usernameInput" class="form-control mb-100" formControlName="usernameInput" type="text" placeholder="username">

                                <div class="float-end">
                                    <button type="submit"
                                            sk-spinner-button [isSpinning]="savingPersonalInfo" [disabled]="savingPersonalInfo || personalInfoForm.invalid || !personalInfoForm.dirty"
                                            color="primary"
                                            class="btn btn-primary mr-100">{{'settings.profile.update' | translate}}
                                    </button>
                                    <button type="button"
                                            (click)="onCancel()"
                                            [disabled]="savingPersonalInfo || personalInfoForm.invalid || !personalInfoForm.dirty"
                                            color="secondary"
                                            class="btn btn-outline-secondary">{{'settings.profile.cancel' | translate}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- ConnectWise Single Sign-On -->
                <sk-connectwise-sso *ngIf="isConnectWiseSSOAllowed"></sk-connectwise-sso>

                <!-- Language & Region -->
                <div class="card mt-200">
                    <div class="card-header sk-card-header d-flex justify-content-between">
                        <h4>{{'settings.profile.language-and-region' | translate}}</h4>
                    </div>
                    <div class="card-block">
                        <div name="languagesAndRegion" novalidate="">
                            <label for="sk-languageDropdown" class="font-medium">{{'settings.LANGUAGE' | translate}}</label>
                            <div ngbDropdown id="sk-languageDropdown" class="w-100 btn-group">
                                <button ngbDropdownToggle [class.active]="savingCulture" type="button" id="accountSelectBtn" class="btn btn-outline-secondary dropdown-toggle text-truncate sk-has-spinner sk-abs-spinner" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="sk-btn-spinner">
                                        <div class="sk-spin-loader loader-dark"></div>
                                    </div>
                                    <span class="sk-loader-label">{{ chosenLanguage | translate }}</span>
                                </button>
                                <div ngbDropdownMenu [style.visibility]="savingCulture ? 'hidden' : 'visible'" class="dropdown-menu">
                                    <a ngbDropdownItem [id]="'sk-languageDropdown-item-' + language.value" *ngFor="let language of languageOptions" (click)="onChangeLanguage(language.value)"
                                    [class.active]="(chosenLanguage === language.key)" class="d-flex justify-content-between">
                                    <span>{{language.key | translate}}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Change Password -->
                <div class="card mt-200" *ngIf="partnerAuthenticationType === authenticationType.SkyKickAuth">
                    <div class="card-header sk-card-header d-flex justify-content-between">
                        <h4>{{'settings.profile.change-password' | translate}}</h4>
                    </div>
                    <div class="card-block">
                        <div name="changePassword" novalidate="">
                            <form class="mb-100"
                                    [formGroup]="changePasswordForm"
                                    (ngSubmit)="onSubmitSavePassword()"
                                    (keydown.enter)="$event.preventDefault()">
                                <div>
                                    <fieldset class="mb-100">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-start">
                                                <label for="oldPassword" class="font-medium">{{'settings.profile.old-password' | translate}}</label>
                                            </div>
                                            <div class="flex">
                                                <button type="button" class="btn sk-btn-icon sk-password-toggle border-0">
                                                    <i class="material-icons md-18 sk-password-show icon-muted" (click)="toggleOldPassword()" (keyup.enter)="toggleOldPassword()">{{showOldPassword ? 'visibility_off' : 'visibility'}}</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="sk-input-group">
                                            <input [type]="showOldPassword ? 'text' : 'password'" class="form-control" id="oldPassword"
                                                formControlName="oldPassword" placeholder="{{'settings.profile.old-password' | translate}}">
                                        </div>
                                    </fieldset>
                                    <fieldset class="mb-100">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-start">
                                                <label for="newPassword" class="font-medium">{{'settings.profile.new-password' | translate}}</label>
                                            </div>
                                            <div class="flex">
                                                <button type="button" class="btn sk-btn-icon sk-password-toggle border-0">
                                                    <i class="material-icons md-18 sk-password-show icon-muted" (click)="toggleNewPassword()" (keyup.enter)="toggleNewPassword()">{{showNewPassword ? 'visibility_off' : 'visibility'}}</i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="sk-input-group">
                                            <input [type]="showNewPassword ? 'text' : 'password'" class="form-control" id="newPassword"
                                                formControlName="newPassword" placeholder="{{'settings.profile.new-password' | translate}}"
                                                [ngbPopover]="passwordPopover" placement="bottom" [autoClose]="'outside'" popoverClass="password-popover" />
                                        </div>
                                    </fieldset>
                                    <ng-template #passwordPopover>
                                        <div class="password-popover-title">{{'platform.password.requirements' | translate}}:</div>
                                        <div class="password-rule-error" [class.password-rule-valid]="isPasswordRuleValid('minlength') && isPasswordRuleValid('maxlength')">{{ 'platform.password.length' | translate }}</div>
                                        <div class="password-rule-error" [class.password-rule-valid]="!hasPasswordPatternErrors()">{{ 'platform.password.pattern' | translate }}</div>
                                        <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasLowerCase')">{{ 'platform.password.lowercase' | translate }}</div>
                                        <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasUpperCase')">{{ 'platform.password.uppercase' | translate }}</div>
                                        <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasNumber')">{{ 'platform.password.number' | translate }}</div>
                                        <div class="password-pattern-error" [class.password-pattern-valid]="isPasswordRuleValid('hasSpecialCharacters')">{{ 'platform.password.special_character' | translate }}</div>
                                    </ng-template>
                                </div>
                                <br>
                                <div class="float-end">
                                    <button type="submit"
                                            sk-spinner-button [isSpinning]="savingPassword" [disabled]="savingPassword || changePasswordForm.invalid || !changePasswordForm.dirty"
                                            color="primary"
                                            class="btn btn-primary">
                                        <span>{{'settings.profile.change-password' | translate}}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- Developer API Access User Profile -->
                <div *ngIf="isUserProfileVisible()" class="card mt-200">
                    <div class="card-header sk-card-header d-flex justify-content-between">
                        <h4>{{'settings.profile.user-profile' | translate}}</h4>
                    </div>
                    <div class="card-block">
                        <div name="developerAPI" novalidate="">
                            <div sk-id-gen="DeveloperApiAccess" class="container" id="sk-adminUserProfileDeveloperApiAccess" aria-hidden="false">
                                <dl *ngIf="partnerInfoModel.ApiDeveloperKey" class="sk-dl">
                                    <dt class="ng-scope">{{'settings.profile.user-id' | translate}}</dt>
                                    <dd class="ng-binding">{{partnerInfoModel.ApiDeveloperKey}}</dd>
                                </dl>

                                <dl class="sk-dl" *ngFor="let subscription of partnerInfoModel.SubscriptionList; let i=index">

                                    <dt class="ng-scope">{{subscription.Name}}</dt>

                                    <dd class="row ng-scope">
                                        <span class="col-md-3 ng-scope">{{'settings.profile.primary-key' | translate}}</span>
                                        <span class="col">
                                            {{toggleKeys[(i*2)] ? [subscription.PrimaryKey] : [keyMask]}}
                                            <a class="ng-scope" (click)="toggleKeys[(i*2)] = !toggleKeys[(i*2)]">{{toggleKeys[(i*2)] ? "Hide" : "Show"}}</a>
                                        </span>
                                    </dd>

                                    <dd class="row ng-scope">
                                        <span class="col-md-3 ng-scope">{{'settings.profile.secondary-key' | translate}}</span>
                                        <span class="col">
                                            {{toggleKeys[(i*2+1)] ? [subscription.SecondaryKey] : [keyMask]}}
                                            <a class="ng-scope" (click)="toggleKeys[(i*2+1)] = !toggleKeys[(i*2+1)]">{{toggleKeys[(i*2+1)] ? "Hide" : "Show"}}</a>
                                        </span>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
