import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ExternalUserCompleteProfileResponse} from '../models/ExternalUserCompleteProfileResponse';

@Injectable({
  providedIn: 'root',
})
export class ExternalCompleteProfileResponseExtractService {
  constructor(private readonly cookieService: CookieService) { }

  public extractExternalCompleteProfileData() {
    const data = this.cookieService.get('external_complete_profile_response');
    if (data) {
      return JSON.parse(data) as ExternalUserCompleteProfileResponse;
    }
    return null;
  }
}
