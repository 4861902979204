import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { TranslateService } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Subject } from 'rxjs';
import { ErrorFactory } from 'src/app/shared/factories/errors.factory';
import { FormValidators } from "../../utilities/form-validators.utils";
import { AuthenticationType } from '../account/models/authentication-type';
import { AuthenticationMethodResourcesService } from '../account/services/authentication-method-resources.service';
import {
  PasswordValidatorService,
} from '../shared/services/password-validator.service';
import { PartnerInfoModel } from './models/PartnerInfoSave';
import { UserProfileService } from './services/user-profile.service';

@Component({
  selector: 'sk-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {

  @Input()
  showOutline: boolean;
  toggleKeys: Boolean[] = [];
  readonly keyMask: string  = "XXXXXXXXXXXXXXXXXXXXXXXXXXX";

  languageOptions = [];
  chosenLanguage: string;

  validationPatter: string = "*";
  savingPersonalInfo: boolean = false;
  savingPassword: boolean = false;
  savingCulture: boolean = false;
  personalInfoForm: UntypedFormGroup;

  changePasswordForm: UntypedFormGroup = new UntypedFormGroup(
    {
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.passwordValidatorService.passwordPatternsMatch()
      ]),
    }
  );

  partnerInfoModel: PartnerInfoModel = new PartnerInfoModel();

  authenticationType = AuthenticationType;
  partnerAuthenticationType: AuthenticationType;

  showOldPassword: Boolean;
  showNewPassword: Boolean;

  private onDestroy$ = new Subject<void>();

  isConnectWiseSSOAllowed = false;

  constructor(
    private userProfileService: UserProfileService,
    private cookieService: CookieService,
    private translateService: TranslateService,
    private passwordValidatorService: PasswordValidatorService,
    private userService: AbstractUserProvider,
    private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
    private toastrService: ToastrService,
    private errorFactory: ErrorFactory) { }

  ngOnInit(): void {
    this.savingPersonalInfo = true;
    this.userProfileService.getInfo()
      .subscribe( partnerInfo => this.updatePersonalInfoForm(partnerInfo.result) )
      .add(() => this.savingPersonalInfo = false);

    const supportedLanguages = [
      { key: "ENGLISH", value: "en" },
      { key: "GERMAN", value: "de" },
      { key: "JAPANESE", value: "ja" }
    ];

    this.languageOptions = supportedLanguages.map(language => ({
      value: language.value,
      key: `settings.LANGUAGES_TRANSLATED.${language.key}`
    }));

    const languageChoice = this.languageOptions.find(option => option.value === this.cookieService.get('skLanguageCode'));
    this.chosenLanguage = languageChoice ? languageChoice.key : this.languageOptions[0].key;

    this.personalInfoForm = new UntypedFormGroup(
      {
        firstNameInput: new UntypedFormControl('', Validators.required),
        lastNameInput: new UntypedFormControl('', Validators.required),
        emailInput: new UntypedFormControl('', Validators.required),
        phoneInput: new UntypedFormControl(''),
        cellPhoneInput: new UntypedFormControl(''),
        usernameInput: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
      },
      { validators: FormValidators.AtLeastOneFieldFormValidator(['phoneInput', 'cellPhoneInput']) }
    );

    this.authenticationMethodResourcesService.getPartnerAuthenticationSettings().pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(res => {
      this.isConnectWiseSSOAllowed = res.isConnectWiseSsoEnabled;
      this.partnerAuthenticationType = res.authenticationType;
    });
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.personalInfoForm.get(fieldName);

    if (!control) {
      return false;
    }

    return (control.invalid || control.value === null || control.value === '') && this.personalInfoForm.touched;
  }


  isUserProfileVisible() {
    return this.partnerInfoModel.ApiDeveloperKey || this.partnerInfoModel.SubscriptionList?.length
  }

  updatePersonalInfoForm(model: PartnerInfoModel): void {
    this.partnerInfoModel = model;

    this.personalInfoForm.patchValue({
      firstNameInput:  this.partnerInfoModel.FirstName,
      lastNameInput: this.partnerInfoModel.LastName,
      emailInput: this.partnerInfoModel.Email,
      phoneInput:  this.partnerInfoModel.Phone,
      cellPhoneInput: this.partnerInfoModel.Cell,
      usernameInput: this.partnerInfoModel.Username,
    });

    if(this.toggleKeys == null)
    {
      if(model.SubscriptionList != null)
      {
        // two boolean flags per subscription,
        // one for primary key, one for secondary key
        let iMax = model.SubscriptionList.length * 2;
        for (let i = 0; i < iMax; i++) {
          this.toggleKeys.push(false);
        }
      }
    }
  }

  onCancel() {
    this.updatePersonalInfoForm(this.partnerInfoModel)
    this.personalInfoForm.markAsPristine();
  }

  onSubmitPersonalInfoForm() {
      this.savingPersonalInfo = true;

      this.partnerInfoModel.FirstName = this.personalInfoForm.value.firstNameInput
      this.partnerInfoModel.LastName = this.personalInfoForm.value.lastNameInput
      this.partnerInfoModel.Email = this.personalInfoForm.value.emailInput
      this.partnerInfoModel.Phone = this.personalInfoForm.value.phoneInput
      this.partnerInfoModel.Cell = this.personalInfoForm.value.cellPhoneInput
      this.partnerInfoModel.Username = this.personalInfoForm.value.usernameInput

      this.userProfileService.saveUserInfo(this.partnerInfoModel).pipe(
          switchMap(result => {
            this.savingPersonalInfo = false;
            this.personalInfoForm.markAsPristine();

            if (result) {
                return this.translateService.get('settings.profile.profile-updated').pipe(
                      tap((successText: string) => this.toastrService.success(successText))
                  );
              }
          }),
          catchError((response: HttpErrorResponse) => this.errorFactory.getMessage(response?.error).pipe(
              tap((errorText: string) => {
                this.toastrService.error(errorText);
                this.savingPersonalInfo = false;
              })
          ))
      ).subscribe();
  }

  onChangeLanguage(selectedLanguage: string) {
    this.savingCulture = true;
    const currentUser = this.userService.getCurrentUser();
    this.userProfileService.changeCulture(currentUser.email, selectedLanguage)
      .subscribe(() => {
        this.cookieService.set('skLanguageCode',selectedLanguage, 9999, "/", ".skykick.com", false, "Lax");
        window.location.reload();
      }
    );
  }

  hasPasswordPatternErrors(): boolean {
    return this.passwordValidatorService.hasPasswordPatternErrors();
  }

  isPasswordRuleValid(ruleName: string): boolean {
    return this.passwordValidatorService.isPasswordRuleValid(this.changePasswordForm.controls.newPassword, ruleName);
  }

  toggleOldPassword(): void {
    this.showOldPassword = !this.showOldPassword;
  }

  toggleNewPassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  onSubmitSavePassword() {
    try {
      this.savingPassword = true;
      this.userProfileService.changePassword(this.changePasswordForm.value)
        .pipe(
          switchMap(result => {
            this.savingPassword = false;

            if (result.success) {
              this.toastrService.success(result.result);
            }
            else
            {
              this.toastrService.error(result.error);
            }

            return EMPTY;
          }),
          catchError(() => {
            this.savingPassword = false;
            return EMPTY;
          })
      ).subscribe();
    }
    catch (ex) { console.log(ex) }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
