import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule,} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule, CardModule, FeedbackModule } from '@skykick/core';
import { SharedModule as SettingsSharedModule } from '../settings/shared/shared.module';
import { SharedModule } from '../shared/shared.module';
import {ConnectWiseProfileFormComponent} from "./connectwise-profile-form";

@NgModule({ declarations: [
    ConnectWiseProfileFormComponent
    ],
    bootstrap: [ConnectWiseProfileFormComponent], imports: [CardModule,
        NgbModule,
        SharedModule,
        SettingsSharedModule,
        FormsModule,
        ButtonsModule,
        FeedbackModule,
        ReactiveFormsModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ConnectWiseProfileFormModule {}
