<div sk-page-header [hideSearch]="true" [hideSettings]="true">
  <h3>
    <span>Notifications</span>
  </h3>

  <ul sk-page-header-nav class="sk-tabs-lg d-flex">
    <li
      *ngFor="let page of Pages"
      sk-page-header-nav-item
      link="{{ page.Route }}"
      [alerts]="page.Alerts"
      [label]="page.Name"
      [hidden]="isConnectWiseUser && !hasPermission(page.Name)"
      [class.sk-tab-disabled]="!hasPermission(page.Name)"
    >
      {{ page.Name }}
    </li>
  </ul>
</div>
<div class="sk-page-content">
  <!-- PANEL LEFT PRIMARY - SIDENAV -->
  <div sk-page-panel name="primary" class="sk-panel-primary"></div>

  <!-- CONTENT COLUMN -->
  <router-outlet></router-outlet>

  <!-- PANEL RIGHT -->
  <div sk-panel></div>
</div>

<div class="sk-page-footer footer-light --d-none">
  <div class="sk-footer-content">
  </div>
</div>
