import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ExternalAuthService {
    public externalAuth() {
        window.location.href = this.getExternalAuthRedirect();
    }

    private getExternalAuthRedirect(): string {
        const fullUrl = new URL(window.location.href);
        const baseUrl = (fullUrl.origin + fullUrl.pathname).toLocaleLowerCase();
        const encodedReturnTo = encodeURIComponent(baseUrl);

        return `${this.getBaseUri()}/api/external-auth?returnTo=${encodedReturnTo}`;
    }

    private getBaseUri() {
        return window.location.href.includes('.com') ? environment.auth.baseUri : environment.auth.alternativeBaseUri;
    }
}
