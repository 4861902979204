import { Component, OnInit } from '@angular/core';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {Language} from "../complete-member-profile-form/models/language.enum";
import { TranslateService } from '@ngx-translate/core';
import {ExternalCompleteProfileResponseExtractService} from "./services/external-complete-profile-response-extract.service";
import {catchError, tap} from "rxjs/operators";
import {InvitationValidationResultType} from "../complete-member-profile-form/models/invitation-validation-result-type";
import {throwError} from "rxjs";
import {MemberProfileService} from "../complete-member-profile-form/services/member-profile.service";
import {FormValidators} from "../utilities/form-validators.utils";
import { ExternalAuthCompleteProfileService } from "../settings/shared/services/external-auth-complete-profile.service";

@Component({
  selector: 'app-connectwise-profile-form',
  templateUrl: './connectwise-profile-form.html',
  styleUrls: ['./connectwise-profile-form.scss']
})
export class ConnectWiseProfileFormComponent implements OnInit {
  loading: boolean = false;
  executingMainAction: boolean = false;
  error: boolean = false;
  ssoSignupError: boolean = false;
  errorTitle: string;
  ssoSignupErrorMessage: string;
  errorDescription: string;
  errorContactLink: string;
  invitationInvalid: boolean = false;
  profileExist: boolean = false;
  invitationExpired: boolean = false;
  invitationUsed: boolean = false;
  formDisabled: boolean = true;
  sessionTimedOut: boolean = false;
  openLanguageDropdown: boolean = false;
  selectedLanguage: string = '';
  languages: Array<string> = Object.keys(Language).map(key => Language[key]);
  token: string = "";
  emailAddress: string = "";
  portalUrl: string = "";
  completeProfileForm: UntypedFormGroup;
  isFormSubmitted: boolean = false;
  year: number = new Date().getFullYear();

  private readonly businessExtensionPattern: string = "^[0-9]+$";

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly memberProfileService: MemberProfileService,
    private readonly externalRegistrationService: ExternalCompleteProfileResponseExtractService,
    private readonly authCompleteProfileService: ExternalAuthCompleteProfileService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    this.formDisabled = false;
    this.initForm();
  }

  initForm(): void {
    const somethingWrongLocKey = 'settings.common.error.something-wrong';
    const requestIssueLocKey = 'settings.common.error.request-issue';
    const tryAgainLocKey = 'settings.common.try-again-later';
    const contactForAssistanceLockKey = 'settings.common.error.contact-for-assistance';
    const externalData = this.externalRegistrationService.extractExternalCompleteProfileData();
    const accountInUse = 'ConnectWise Account is already used';
    const noAPIAccess = 'Required access to Users API was not provided';

    this.translateService.get([somethingWrongLocKey, requestIssueLocKey, tryAgainLocKey, contactForAssistanceLockKey])
      .pipe(
        tap(translations => {
          this.errorTitle = translations[somethingWrongLocKey];
          this.errorDescription = `${translations[requestIssueLocKey]}. ${translations[tryAgainLocKey]}`;
          this.errorContactLink = translations[contactForAssistanceLockKey];
        }))
      .subscribe();

    this.memberProfileService.validateInvitation(this.token).pipe(
      tap((response) => {
        this.portalUrl = response.PortalUrl;
        if (response.EmailAddress) {
          this.emailAddress = response.EmailAddress;
        }
        switch (response.InvitationValidationResultType) {
          case InvitationValidationResultType.InvitationValid:
            this.formDisabled = false;
            break;
          case InvitationValidationResultType.InvitationExpired:
            this.invitationExpired = true;
            break;
          case InvitationValidationResultType.InvitationUsed:
            this.invitationUsed = true;
            break;
          default:
            this.invitationInvalid = true;
        }
        this.loading = false;
      }),
      catchError((error) => {
        this.error = true;
        this.loading = false;
        return throwError(() => error);
      })
    ).subscribe(() => {
      if(externalData.ErrorMessage){
        this.ssoSignupError = true;
        this.formDisabled = true;
        if (externalData.ErrorMessage.includes(accountInUse)) {
          this.translate.get('complete.form.errors.account_already_used').subscribe((res: string) => {
            this.ssoSignupErrorMessage = res;
            this.profileExist = true;
          });
        } else if (externalData.ErrorMessage.includes(noAPIAccess)) {
          this.translate.get('complete.form.errors.required_api_access').subscribe((res: string) => {
            this.ssoSignupErrorMessage = res;
          });
        }
      } else if(!externalData){
        this.sessionTimedOut = true;
        this.formDisabled = true;
        this.translate.get('complete.form.errors.session_timed_out').subscribe((res: string) => {
          this.ssoSignupErrorMessage = res;
        });
      }
    });

    this.completeProfileForm = this.formBuilder.group({
      firstName: [{value: externalData?.Data?.FirstName || '', disabled: true}],
      lastName: [{ value: externalData?.Data?.LastName || '', disabled: true}],
      emailAddress: [{ value: this.emailAddress || '', disabled: true}],
      jobTitle: [''],
      cellPhone: [''],
      businessPhone: [''],
      extension: new UntypedFormControl('', [ Validators.pattern(this.businessExtensionPattern), Validators.min(1), Validators.max(9999999999)]),
    },{
      validators: FormValidators.AtLeastOneFieldFormValidator(['cellPhone', 'businessPhone'])
    });
  }

  isFieldInvalid(fieldName: string): boolean {
    return this.isFormSubmitted && this.completeProfileForm.get(fieldName).invalid;
  }

  isFieldPatternInvalid(fieldName: string): boolean {
    return this.isFormSubmitted && this.completeProfileForm.get(fieldName).hasError("pattern");
  }

  isFieldRangeInvalid(fieldName: string): boolean {
    return this.isFormSubmitted && (this.completeProfileForm.get(fieldName).hasError("min") || this.completeProfileForm.get(fieldName).hasError("max"));
  }

  toggleLanguageDropdown($event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.openLanguageDropdown = !this.openLanguageDropdown;
  }

  setLanguage(language: string) {
    this.selectedLanguage = language;
    this.translateService.use(language);
    this.openLanguageDropdown = false;
  }

  markInvalidFormFields() {
    this.completeProfileForm.markAllAsTouched();
    Object.keys(this.completeProfileForm.controls).forEach(field => {
      const control = this.completeProfileForm.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  completeMemberProfile() {
    if (this.formDisabled) return;

    this.isFormSubmitted = true;

    if (this.completeProfileForm.invalid) {
      this.markInvalidFormFields();
      return;
    }

    this.executingMainAction = true;
    this.memberProfileService.completeProfile({
        FirstName: this.completeProfileForm.controls.firstName.value,
        LastName: this.completeProfileForm.controls.lastName.value,
        JobTitle: this.completeProfileForm.controls.jobTitle.value,
        CellPhone: this.completeProfileForm.controls.cellPhone.value,
        BusinessPhone: this.completeProfileForm.controls.businessPhone.value,
        Extension: this.completeProfileForm.controls.extension.value,
        Password: null
      },
      this.token).pipe(
      tap((response) => {
        window.location.href = response.PortalUrl;
      }),
      catchError((error) => {
        this.error = true;
        this.executingMainAction = false;
        return throwError(() => error);
      })
    ).subscribe();
  }

  retryGetCompleteProfileData() {
    this.authCompleteProfileService.externalAuth(this.token);
  }

  navigateToLogin() {
    window.location.href = this.portalUrl;
  }
}
