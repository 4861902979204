<nav class="navbar sk-portal-header">
  <a href="../"><img class="ml-100" src="https://cdn.skykick.com/logo/masthead-logo.svg" height="28" alt="ConnectWise Logo"></a>
</nav>
<div class="sk-content-primary bg-gray-50">
  <div class="sk-content-flex">
    <div *ngIf="loading && !error" class="flex-center-center pb-100">
      <div class="sk-spin-loader"></div>
    </div>
    <div *ngIf="error" class="flex-top-center pb-100">
      <sk-error-banner [title]="errorTitle" [description]="errorDescription" [contactLink]="errorContactLink"></sk-error-banner>
    </div>
    <div *ngIf="!loading" class="sk-scroll-container align-items-center">
      <div>
        <div class="col-lg col-lg-12 mt-200 mb-200 text-lg-center">
          <h1>{{ 'complete.form.complete_your_account_profile' | translate }}</h1>
        </div>
        <form class="card mb-200" disabled="formDisabled" [formGroup]="completeProfileForm">
          <div class="card-body">
            <div class="row">
              <div class="col-xl mb-150 mt-50 col-xl-12" >
                <h3>{{ 'complete.form.account' | translate }}</h3>
              </div>
            </div>
            <sk-feedback *ngIf="invitationInvalid" class="mb-100" type="danger"
                         [subject]="'complete.form.actions.invitation_link_is_invalid' | translate" [message]="'complete.form.actions.contact_partner' | translate">
            </sk-feedback>
            <sk-feedback *ngIf="invitationUsed" class="mb-100" type="info"
                         [subject]="'complete.form.actions.profile_is_completed' | translate">
              <span translate>complete.form.actions.you_can_login</span>&nbsp;<a [href]="portalUrl">{{ 'complete.form.actions.skykick_portal' | translate }}</a>
            </sk-feedback>
            <sk-feedback *ngIf="invitationExpired" class="mb-100" type="info"
                         [subject]="'complete.form.actions.invitation_link_expired' | translate" [message]="'complete.form.actions.check_your_email' | translate">
            </sk-feedback>
            <sk-feedback class="mb-100" *ngIf="ssoSignupError && !profileExist"  type="danger" [subject]="'settings.notifications.error_title' | translate" [message]="ssoSignupErrorMessage">
              <button sk-button (click)="retryGetCompleteProfileData()" class="btn btn-outline-primary mr-100 text-truncate" translate>
                settings.common.try-again
              </button>
            </sk-feedback>
            <sk-feedback class="mb-100" *ngIf="profileExist"  type="danger" [subject]="'settings.notifications.error_title' | translate" [message]="ssoSignupErrorMessage">
              <button sk-button (click)="navigateToLogin()" class="btn btn-outline-primary mr-100 text-truncate" translate>
                complete.form.actions.login
              </button>
            </sk-feedback>
            <div class="row">
              <fieldset class="col-xl mb-100 col-xl-12">
                <label class="form-label font-bold" for="firstName">{{ 'complete.form.first_name' | translate }}</label>
                <input type="text" class="form-control" name="firstName" formControlName="firstName" disabled />
              </fieldset>
            </div>
            <div class="row">
              <fieldset class="col-xl mb-100 col-xl-12">
                <label class="form-label font-bold" for="lastName">{{ 'complete.form.last_name' | translate }}</label>
                <input type="text" class="form-control" name="lastName" formControlName="lastName" disabled />
              </fieldset>
            </div>
            <div class="row">
              <fieldset class="col-xl mb-100 col-xl-12" >
                <label class="form-label font-bold" for="emailAddress">{{ 'complete.form.email_address' | translate }}</label>
                <input type="text" class="form-control" name="emailAddress" value="{{ emailAddress }}" disabled />
              </fieldset>
            </div>
            <div class="row">
              <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                <label class="form-label font-bold" for="jobTitle">{{ 'complete.form.job_title' | translate }}</label>
                <input type="text" class="form-control" name="jobTitle" formControlName="jobTitle" />
              </fieldset>
            </div>
            <div class="row">
              <fieldset class="col-xl mb-100 col-xl-12" [disabled]="formDisabled">
                <label class="form-label font-bold" for="cellPhone">{{ 'complete.form.cell_phone' | translate }}</label>
                <input type="text" class="form-control" name="cellPhone" formControlName="cellPhone" [class.is-invalid]="isFieldInvalid('cellPhone')" />
                <small *ngIf="isFieldInvalid('cellPhone')" class="invalid-feedback">{{ 'complete.form.errors.phone_required' | translate }}</small>
              </fieldset>
            </div>
            <div class="row">
              <fieldset class="col-sm mb-100 col-sm-8" [disabled]="formDisabled">
                <label class="form-label font-bold" for="businessPhone">{{ 'complete.form.business_phone' | translate }}</label>
                <input type="text" class="form-control" name="businessPhone" formControlName="businessPhone" [class.is-invalid]="isFieldInvalid('businessPhone')" />
                <small *ngIf="isFieldInvalid('businessPhone')" class="invalid-feedback">{{ 'complete.form.errors.phone_required' | translate }}</small>
              </fieldset>
              <fieldset class="col-sm mb-100 col-sm-4" [disabled]="formDisabled">
                <label class="form-label font-bold" for="extension">{{ 'complete.form.extension' | translate }}</label>
                <input type="text" class="form-control" name="extension" formControlName="extension" [class.is-invalid]="isFieldInvalid('extension')" />
                <small *ngIf="isFieldPatternInvalid('extension')" class="invalid-feedback">{{ 'complete.form.errors.extension_field_pattern_is_invalid' | translate }}</small>
                <small *ngIf="isFieldRangeInvalid('extension')" class="invalid-feedback">{{ 'complete.form.errors.extension_field_range_is_invalid' | translate }}</small>
              </fieldset>
            </div>
            <div class="row">
              <div class="d-grid col-xl mb-50 mt-50 col-xl-12">
                <button sk-spinner-button [color]="'primary'" class="btn-lg" (click)="completeMemberProfile()"
                        [disabled]="formDisabled" [isSpinning]="executingMainAction">{{ 'complete.form.actions.complete_profile' | translate }}
                </button>
              </div>
            </div>
          </div>
        </form>
        <div>
          <div class="mb-100">
            <div ngbDropdown class="btn-group dropup m-b-1" [ngClass]="{'open': openLanguageDropdown}">
              <button ngbDropdownToggle type="button" (click)="toggleLanguageDropdown($event)" class="btn btn-basic dropdown-toggle">{{ 'complete.form.language' | translate }}</button>
              <div ngbDropdownMenu class="dropdown-menu">
                <a ngbDropdownItem class="dropdown-item" [ngClass]="{'active': language === selectedLanguage}" (click)="setLanguage(language)" *ngFor="let language of languages">
                  {{ 'platform.languages.' + language | translate }}
                </a>
              </div>
            </div>&nbsp;&nbsp;
            <span>{{ 'complete.form.copyright' | translate }} {{ year }} {{ 'complete.form.all_rights_reserved' | translate }}</span>&nbsp;&nbsp;
            <a href="https://www.skykick.com/partner-terms-conditions">{{ 'complete.form.terms_and_conditions' | translate }}</a>&nbsp;&nbsp;
            <a href="https://www.skykick.com/privacy-policy">{{ 'complete.form.privacy_policy' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
