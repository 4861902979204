<!-- CONTENT COLUMN -->
<div class="sk-content-column">
    <div class="flex-center-center pb-100" [ngClass]="{'d-none': !initialLoading}">
        <div class="sk-spin-loader"></div>
    </div>
    <!-- Heading  -->
    <div *ngIf="editState && member" class="d-flex flex-row align-items-center justify-content-between py-100 px-200 border-bottom">
        <!-- Heading Left -->
        <div class="d-flex flex-row align-items-center">
            <!-- Avatar -->
            <sk-avatar class="d-flex align-items-center square-56 mr-100" size="lg" [fullName]="member.fullName">
            </sk-avatar>

            <div class="d-flex flex-column">
                <h2 class="font-weight-normal">{{ member.fullName }}</h2>
                <span class="text-muted">{{ member.email }}</span>
            </div>
        </div>
        <!-- Heading Right -->
        <div class="d-flex align-items-center">
            <sk-actions-menu
                [showOutline] = "true"
                *ngIf="member?.status === 'Active'">
                <a (click)="resetPassword(member)"
                    class="dropdown-item"
                    [hidden]="isResetPasswordUnavailable"
                    ngbDropdownItem>{{ 'settings.members.actions.reset-password' | translate }}</a>
                <a *ngIf="!isOwnMember()"
                    (click)="deactivateAccount(member)"
                    class="dropdown-item text-danger"
                    ngbDropdownItem>{{ 'settings.members.actions.deactivate-account' | translate }}</a>
            </sk-actions-menu>
        </div>
    </div>
    <!-- /.flex-row -->

    <!-- CONTENT PRIMARY - SCROLLABLE -->
    <div *ngIf="member" class="sk-content-primary">
        <div class="sk-flex-table">
            <sk-m365-authentication-alert [partnerAuthentication]="partnerAuthentication" [groupsSyncEnabled]="groupsSyncEnabled" [m365AuthConnectionStatus]="m365AuthConnectionStatus">
            </sk-m365-authentication-alert>
            <div id="scrollWrap" class="sk-scroll-container">
                <div class="d-flex flex-column min-width-lg max-width-lg pt-150">
                    <div class="row">
                        <div class="col-10">
                            <div class="pb-200">

                                <!-- Email -->
                                <div *ngIf="!editState" class="align-items-center pb-100">
                                    <h3 class="font-400 pb-50">{{ 'settings.members.add-a-member' | translate }}</h3>
                                    <div class="d-inline-flex">
                                        <p>{{ 'settings.members.add-member-desc' | translate }}</p>
                                    </div>
                                    <form [formGroup]="addMemberForm" novalidate>
                                        <fieldset class="mb-100">
                                            <label class="font-medium">{{ 'settings.common.email.address' | translate }}</label>
                                            <div *ngIf="!isM365Authentication()" class="sk-input-icon input-width-xl">
                                                <input
                                                    (keyup)="resetEmailValidation()"
                                                    type="text"
                                                    class="form-control"
                                                    [class.is-invalid]="!addMemberEmailIsValid || !addMemberEmailIsUnique"
                                                    formControlName="email"/>
                                                <i *ngIf="addMemberForm.controls.email.pending" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                                            </div>
                                            <div *ngIf="isM365Authentication()" class="sk-input-icon input-width-xl">
                                                <input
                                                    class="form-control"
                                                    [class.is-invalid]="!addMemberEmailIsValid || !addMemberEmailIsUnique"
                                                    aria-label="Search"
                                                    formControlName="email"
                                                    [ngbTypeahead]="searchEmail"
                                                    [inputFormatter]="searchFormatter"
                                                    [resultFormatter]="searchFormatter"
                                                    (click)="click$.next($any($event).target.value)"
                                                    (selectItem)="selectItem($event)"
                                                    (keyup)="resetEmailValidation()"
                                                    placeholder="{{ 'settings.members.form.search-for-member' | translate }}" />
                                                <i *ngIf="loadingEmail" class="sk-spin-loader loader-sm material-icons spin-component-custom"></i>
                                                <i *ngIf="!emailQueryIsEmpty && !loadingEmail" (click)="onEmailSearchClear()" class="material-icons md-18 sk-reset-search-btn ng-star-inserted" title="Reset and Exit Search"></i>
                                                <i *ngIf="emailQueryIsEmpty && !loadingEmail" class="material-icons md-18">search</i>
                                            </div>
                                            <small *ngIf="!addMemberEmailIsValid"
                                                class="invalid-feedback">{{ 'settings.common.email.invalid' | translate }}</small>
                                            <small *ngIf="!addMemberEmailIsUnique"
                                                class="invalid-feedback">{{ 'settings.common.email.exists' | translate }}</small>
                                        </fieldset>
                                    </form>
                                </div>

                                <!-- Roles -->
                                <div class="border-bottom">
                                    <h4 class="pb-50">{{ 'settings.members.form.connectwise-backup-only-account' | translate }}</h4>
                                    <fieldset class="mb-100">
                                        <ng-container *ngFor="let roleRadio of rolesRadioButtons; let i = index">
                                            <div class="form-check">
                                                <input type="radio" id="roleRadio{{i}}" name="roleRadio" class="form-check-input"
                                                    [value]="roleRadio.roleKey"  [checked]="member.role === roleRadio.roleKey"
                                                    [disabled]="isOwnMember() ? true : null" [class.is-invalid]="!isRoleSelected"
                                                    (click)="changeRole(roleRadio.roleKey)" (keyup.enter)="changeRole(roleRadio.roleKey)">
                                                <label class="form-check-label" for="roleRadio{{i}}">{{ roleRadio.roleName | translate }}</label>
                                                <small class="sk-form-text">
                                                    <ul class="sk-ul pl-100">
                                                        <li *ngFor="let desc of roleRadio.descriptionList">{{ desc }}</li>
                                                    </ul>
                                                </small>
                                            </div>
                                        </ng-container>
                                        <small *ngIf="!isRoleSelected" class="invalid-feedback">
                                            {{ 'settings.members.form.value-required' | translate }}
                                        </small>
                                    </fieldset>
                                </div>

                                <!-- Cloud Backup -->
                                <div class="border-bottom py-150">
                                    <h4 class="pb-50">{{ cloudBackupCheckbox.permissionName | translate }}</h4>
                                    <div class="pl-50">
                                        <label class="form-check-label">
                                            {{ 'settings.members.permissions.full-access' | translate }}
                                        </label>
                                        <small class="sk-form-text">
                                            <ul class="sk-ul pl-100">
                                                <li *ngFor="let desc of cloudBackupCheckbox.descriptionList">{{ desc }}</li>
                                            </ul>
                                        </small>
                                    </div>
                                </div>

                                <!-- Authentication -->
                                <div class="py-150">
                                    <h4 class="pb-50">{{ 'settings.common.authentication.authentication' | translate }}</h4>
                                    <div class="mb-0">
                                        <div class="row mb-100">
                                            <div class="col-auto mb-50">
                                                <span class="badge badge-default mb-50" [class.badge-info]="isM365Authentication()" [class.connectwise-badge]="isConnectWiseAuthentication()">
                                                    {{ getAuthTypeLocKey() | translate }}
                                                </span>
                                                <p *ngIf="isConnectWiseSsoAllowed()">
                                                  {{ 'settings.common.authentication.connectwise-sso-allowed' | translate }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <small class="sk-form-text" [innerHtml]="'settings.common.authentication.to-change-auth-members'
                                                | translate: { accountSettingsPageUrl: getAccountSettingsPageUrl() }">
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.col -->
                        <div class="col-2">
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
            </div>
        </div>
        <!-- /.sk-flex-table -->
    </div>
    <!-- /.sk-content-primary -->

    <div *ngIf="member" class="sk-page-footer">
        <!-- Large Buttons -->
        <div class="sk-footer-content">
            <button *ngIf="!isM365Authentication() && !editState" sk-spinner-button class="btn-lg mr-100" [color]="'primary'" (mousedown)="sendInvite()"
                [disabled]="isSubmitDisabled()" [isSpinning]="executingMainAction">
                {{ 'settings.members.form.send-invite' | translate }}
            </button>
            <button *ngIf="isM365Authentication() && !editState" sk-spinner-button class="btn-lg mr-100" [color]="'primary'" (mousedown)="addM365Member()"
                [disabled]="isSubmitDisabled()" [isSpinning]="executingMainAction">
                {{ 'settings.members.form.add-member' | translate }}
            </button>
            <button *ngIf="editState" sk-spinner-button class="btn-lg mr-100" [color]="'primary'" (mousedown)="updateMember()"
                [isSpinning]="updating">
                {{ 'settings.members.actions.update-member' | translate }}
            </button>
            <button (click)="cancel()" type="button" class="btn btn-lg btn-outline-secondary mr-150">{{ 'settings.common.cancel' | translate }}</button>
            <div *ngIf="!isFormValid()" class="d-flex align-items-center">
                <i class="material-icons md-20 icon-danger">warning</i>
                <small class="text-danger ml-50">{{ 'settings.members.form.complete-form' | translate }}</small>
            </div>
        </div>
    </div>
</div>
