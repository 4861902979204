<div class="sk-page-header">
  <div class="sk-navbar-primary single-navbar">
    <div class="d-flex align-items-center min-w-0">
      <div class="d-flex min-w-0">
        <div class="min-w-0">
          <h3>{{ 'settings.navigation.account' | translate }}</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sk-page-content">
  <div class="sk-content-primary">
    <!-- <router-outlet></router-outlet> -->

    <div class="sk-flex-table">
      <div class="sk-scroll-container">
        <div class="d-flex flex-column min-width-lg max-width-lg py-200">
          <div class="row">
            <div class="col-8">
              <sk-company-profile></sk-company-profile>

              <sk-authentication></sk-authentication>

              <sk-platform-branding></sk-platform-branding>

              <sk-distributors *ngIf="!isConnectWiseUser"></sk-distributors>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PANEL RIGHT -->
  <div sk-panel></div>
</div>
